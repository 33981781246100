import { useContext, useEffect, useRef, useState } from "react";
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import ProtectedRoute from "./ProtectedRoute";
import { RootStoreContext } from "../stores/rootStore";
import Dashboard from "../../features/dashboard/Dashboard";
import LandingPage from "../../features/landing/LandingPage";
import NavBar from "../../features/nav/Navbar";
import LoginPage from "../../features/user/LoginPage";
import LogoutPage from "../../features/user/LogoutPage";
import { NotFound, muiStyles } from "@intouchhealth/cig-components";
import SilentRenew from "../../features/user/SilentRenew";
import { PageNotFound } from "../constant/errorMessages";
import {
  DASHBOARD_URL,
  HOME_URL,
  LOGIN_URL,
  LOGOUT_URL,
  SILENT_RENEW_CALLBACK_URL,
} from "../constant/routes";
import { getFirstUrl } from "../utils/getFirstUrl";
import { getMicrositesMap } from "../utils/getMicrositesMap";
import { availableMicrosites } from "./getMicrositesRoutes";
import { Loader } from "../../features/_components/Loader";
import { loadingMessage } from "../constant/loadingMessages";
import Banner from "../../features/_components/Banner";

const { styled } = muiStyles;

const HhsRoutes: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const [selectedValue, setSelectedValue] = useState<number | string>("");
  const { appLoaded } = rootStore.commonStore;
  const { getUserApplications, userApplications, isUserApplicationsLoading } =
    rootStore.applicationStore;
  const { isLoggedIn, logout, redirectUrl } = rootStore.userStore;

  const didLogRef = useRef(false);
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async function getUserApps() {
      if (didLogRef.current === false && isLoggedIn) {
        didLogRef.current = true;
        await getUserApplications();
      }
    })();
  }, [isLoggedIn]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userApplications) {
      const micrositeMap = getMicrositesMap(userApplications);

      if (micrositeMap[location.pathname] !== undefined) {
        setSelectedValue(micrositeMap[location.pathname]);
      } else {
        setSelectedValue("");
      }

      let micrositeName = `/${getFirstUrl(location.pathname)}`;

      if (selectedValue === "" && micrositeMap[micrositeName] !== undefined) {
        setSelectedValue(micrositeMap[micrositeName]);
      }
    }
  }, [userApplications, location]); //eslint-disable-line react-hooks/exhaustive-deps

  if (!appLoaded) return <Loader message={loadingMessage} />;

  window.addEventListener("storage", () => {
    window.localStorage.getItem("logout") === "true" && logout();
  });

  return (
    <>
      <Banner />
      <NavBar selectedValue={selectedValue} />
      <FlexContainer>
        <Routes>
          <Route
            path={HOME_URL}
            element={
              !isLoggedIn ? (
                <LandingPage />
              ) : (
                <Navigate to={redirectUrl} replace />
              )
            }
          />
          <Route path={LOGIN_URL} element={<LoginPage />} />
          <Route path={LOGOUT_URL} element={<LogoutPage />} />
          <Route
            path={DASHBOARD_URL}
            element={<ProtectedRoute component={<Dashboard />} />}
          />
          {availableMicrosites(userApplications, isUserApplicationsLoading)}
          <Route
            path={SILENT_RENEW_CALLBACK_URL}
            element={<ProtectedRoute component={<SilentRenew />} />}
          />
          <Route
            path="*"
            element={
              <NotFound
                errorData={PageNotFound}
                onClick={() => navigate(DASHBOARD_URL)}
              />
            }
          />
          <Route path={HOME_URL} element={<Dashboard />} />
        </Routes>
      </FlexContainer>
    </>
  );
};

export default observer(HhsRoutes);

const FlexContainer = styled(`div`)(({ theme }) => ({
  display: "flex",
  flex: 1,
  paddingTop: "4rem",
  backgroundColor: `${theme.palette.background.default}`,
}));
